<script setup lang="ts">
import type {Vendor} from "~/types/types";
import VendorBanner from "~/components/vendors/VendorBanner.vue";

const {data} = await useFetch(useRuntimeConfig().public.baseURL + '/stores/' + useRoute().params.id)
const vendor = data.value.data as Vendor

useSeoMeta({
  title: () => `${vendor.name} | MCModels Vendor`,
  description: 'Find and purchase high-quality 3D models, textures, and graphics at MCModels. Elevate your projects with our extensive collection of assets tailored for game developers, architects, and creatives.',
  ogTitle: 'MCModels: Premier Marketplace for 3D Assets',
  ogDescription: () => `Browse ${vendor.name}'s vendor store on MCModels. Find lots of high quality assets in their own unique style.`,
  ogImage: () => `${vendor.logo !== null ? vendor.logo : '/img/default_person.webp'}`,
  ogUrl: 'https://mcmodels.net/path/to/your/twitter-card-image.jpg',
  twitterCard: 'summary_large_image'
})
</script>

<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <LayoutBreadcrumbs :breadcrumbs="[{ id: 1, name: 'Home', href: '/' }, {id: 2, name: 'Vendors', href: '/vendors'}, {id: 3, name: vendor.name, href: '/vendors/' + vendor.id + '/' + vendor.slug}]"/>
    <VendorBanner :vendor="vendor"/>
    <div class="max-w-[1440px] mx-auto">
      <ProductList :store_id="vendor.id"/>
    </div>
  </div>

</template>

<style scoped>

</style>